import { tns } from "tiny-slider/src/tiny-slider";

function initSlider(selector) {
  const sliderContainer = document.querySelector(selector);

  if (sliderContainer) {
    tns({
      container: sliderContainer,
      autoplay: false,
      loop: false,
      gutter: 0,
      mouseDrag: true,
      controlsPosition: "bottom",
      controlsText: [" ", " "],
      items: 1,
      responsive: {
        720: {
          gutter: 15,
          items: 2,
        },
      },
    });
  }
}

window.addEventListener("DOMContentLoaded", () => initSlider("#slider"));
